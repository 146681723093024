import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import queryString from 'query-string'
import Helmet from 'react-helmet'

import SearchGrid from '../../components/search/grid/searchGrid'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const SearchResults = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Location>
      {({ location }) => (
        <Layout location={location} title={siteTitle} lang={'en'}>
          <SEO title="Search results" lang="en" />
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>

          <SearchGrid
            searchTerm={
              location.search ? queryString.parse(location.search).query : null
            }
            searchCategory={
              location.search
                ? queryString.parse(location.search).category
                : null
            }
            language={'en'}
          />
        </Layout>
      )}
    </Location>
  )
}

export default SearchResults

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
